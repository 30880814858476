import { Button } from 'components/Button/Button';
import { useDevice } from 'lib/hooks/useDevice';
import { useCustomNavigate } from 'lib/hooks/useCustomNavigate';
import { stagePathConfig } from 'data/stage';
import { TButtonType } from 'components/Button/Button.type';

type Props = {
  text: string;
  submit: () => void;
  isLoading: boolean;
  isDisabled: boolean;
  buttonType: TButtonType;
};

const PaymentSubmit = ({
  submit,
  isLoading,
  isDisabled,
  text,
  buttonType,
}: Props) => {
  const navigate = useCustomNavigate();
  const { isMobile } = useDevice();

  return (
    <div>
      <hr className="md:hidden border-none h-px w-full bg-light-800" />
      <div className="p-5 bg-light-700 grid grid-cols-[1fr_2fr] gap-5 md:flex justify-between items-center">
        <Button
          text="Back"
          clickHandler={() => navigate(stagePathConfig.traveller)}
          size="medium"
          type="secondary-border"
          arrow="left"
          className="min-w-[6.25rem]"
          textClassName="!text-base"
        />
        <Button
          text={text}
          clickHandler={submit}
          size="medium"
          type={buttonType}
          isDisabled={isLoading}
          isLoading={isDisabled}
          arrow={isMobile ? undefined : 'right'}
          className="w-auto md:min-w-[15.625rem]"
        />
      </div>
    </div>
  );
};

export { PaymentSubmit };
